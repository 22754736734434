<template>
  <div class="padding-sm" v-loading="statisticsLoading">

    <div class="padding-sm" >
      <div style="padding:10px 0;display: flex;justify-content: flex-start;align-items: center">
        <div>统计日期</div>
        <div class="margin-l-sm">
          <el-date-picker
              size="mini"
              v-model="date"
              value-format="yyyy-MM-dd"
              type="daterange"
              placeholder="选择日期"
              :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <el-button class="margin-l-sm" type="primary" size="mini" @click="search">查询</el-button>
      </div>
    </div>



    <div class="padding-sm" v-for="item in statistics" :key="item.id">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head" style="margin:0">
          <el-row :gutter="24">
            <el-col :span="5">
              <div class="text-center text-gray">课程顾问
                <el-tooltip class="item" effect="dark" content="课程顾问" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{item.name}}</div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">今日微信添加数量
                <el-tooltip class="item" effect="dark" content="今日微信添加数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">
                <el-popover
                    placement="bottom"
                    width="300"
                    trigger="click"
                >
                  <div v-loading="wxCountDataLoading" style="max-height: 400px;overflow: auto">
                    <div v-for="(item,index) in wxCountData" :key="index" class="margin-b-sm"  style="display: flex;justify-content: space-around;align-items: center">
                      <p>{{item.name}}</p>
                      <p>{{item.count}}</p>
                    </div>
                  </div>
                  <el-link type="primary" slot="reference" @click="wxCountDetail('day',item.id)">{{item.todayWxCount}}</el-link>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">本周微信添加数量
                <el-tooltip class="item" effect="dark" content="本周微信添加数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">
                <el-popover
                    placement="bottom"
                    width="300"
                    trigger="click"
                >
                  <div v-loading="wxCountDataLoading" style="max-height: 400px;overflow: auto">
                    <div v-for="(item,index) in wxCountData" :key="index" class="margin-b-sm"  style="display: flex;justify-content: space-around;align-items: center">
                      <p>{{item.name}}</p>
                      <p >{{item.count}}</p>
                    </div>
                  </div>
                  <el-link type="primary" slot="reference" @click="wxCountDetail('week',item.id)">{{item.weekWxCount}}</el-link>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">本月微信添加数量
                <el-tooltip class="item" effect="dark" content="本月微信添加数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">
                <el-popover
                    placement="bottom"
                    width="300"
                    trigger="click"
                >
                  <div v-loading="wxCountDataLoading" class="padding-sm" style="max-height: 400px;overflow: auto">
                    <div v-for="(item,index) in wxCountData" :key="index" class="margin-b-sm"  style="display: flex;justify-content: space-around;align-items: center;">
                      <p>{{item.name}}</p>
                      <p >{{item.count}}</p>
                    </div>
                  </div>
                  <el-link type="primary" slot="reference" @click="wxCountDetail('month',item.id)">{{item.monthWxCount}}</el-link>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="text-center text-gray">微信添加总数量
                <el-tooltip class="item" effect="dark" content="微信添加总数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">
                <el-popover
                    placement="bottom"
                    width="300"
                    trigger="click"
                >
                  <div v-loading="wxCountDataLoading" style="max-height: 400px;overflow: auto">
                    <div v-for="(item,index) in wxCountData" :key="index" class="margin-b-sm"  style="display: flex;justify-content: space-around;align-items: center">
                      <p>{{item.name}}</p>
                      <p >{{item.count}}</p>
                    </div>
                  </div>
                  <el-link type="primary" slot="reference" @click="wxCountDetail('year',item.id)">{{item.totalWxCount}}</el-link>
                </el-popover>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top:25px" :gutter="24">
            <el-col :span="5">
              <div class="text-center text-gray">今日签单数量
                <el-tooltip class="item" effect="dark" content="今日签单数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{item.todayCount}}</div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">今日业绩
                <el-tooltip class="item" effect="dark" content="今日业绩" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">
                <el-popover
                    placement="bottom"
                    width="600"
                    trigger="click"
                >
                  <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                    <div v-for="(item,index) in wxCountData" :key="index">
                      <p>{{type[item.type]}}</p>
                      <p class="margin-t-sm">{{item.price}}</p>
                    </div>
                  </div>
                  <el-link type="primary" slot="reference" @click="statisticsDetail(1,item.id)">{{item.todayPrice}}</el-link>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">年度总签单数量
                <el-tooltip class="item" effect="dark" content="年度总签单数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{item.totalCount}}</div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">本周业绩
                <el-tooltip class="item" effect="dark" content="本周业绩" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">
                <el-popover
                    placement="bottom"
                    width="600"
                    trigger="click"
                >
                  <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                    <div v-for="(item,index) in wxCountData" :key="index">
                      <p>{{type[item.type]}}</p>
                      <p class="margin-t-sm">{{item.price}}</p>
                    </div>
                  </div>
                  <el-link type="primary" slot="reference" @click="statisticsDetail(2,item.id)">{{item.weekPrice}}</el-link>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="text-center text-gray">本月业绩
                <el-tooltip class="item" effect="dark" content="本月业绩" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">
                <el-popover
                    placement="bottom"
                    width="600"
                    trigger="click"
                >
                  <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                    <div v-for="(item,index) in wxCountData" :key="index">
                      <p>{{type[item.type]}}</p>
                      <p class="margin-t-sm">{{item.price}}</p>
                    </div>
                  </div>
                  <el-link type="primary" slot="reference" @click="statisticsDetail(3,item.id)">{{item.monthPrice}}</el-link>
                </el-popover>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top:25px" :gutter="24">
            <el-col :span="5">
              <div class="text-center text-gray">年度总业绩
                <el-tooltip class="item" effect="dark" content="年度总业绩" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">
                <el-popover
                    placement="bottom"
                    width="600"
                    trigger="click"
                >
                  <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                    <div v-for="(item,index) in wxCountData" :key="index">
                      <p>{{type[item.type]}}</p>
                      <p class="margin-t-sm">{{item.price}}</p>
                    </div>
                  </div>
                  <el-link type="primary" slot="reference" @click="statisticsDetail(4,item.id)">{{item.totalPrice}}</el-link>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">本月退费数量
                <el-tooltip class="item" effect="dark" content="本月退费数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{item.monthRefundCount}}</div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">本月退费金额
                <el-tooltip class="item" effect="dark" content="本月退费金额" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{item.monthRefundPrice}}</div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">年度总退费数量
                <el-tooltip class="item" effect="dark" content="年度总退费数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{item.totalRefundCount}}</div>
            </el-col>
            <el-col :span="4">
              <div class="text-center text-gray">年度总退费金额
                <el-tooltip class="item" effect="dark" content="年度总退费金额" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{item.totalRefundPrice}}</div>
            </el-col>
          </el-row>
          <el-row style="margin-top:25px" :gutter="24">
            <el-col :span="5">
              <div class="text-center text-gray">日转换率
                <el-tooltip class="item" effect="dark" content="今日签单数量 / 今日添加微信数" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{((item.todayCount / item.todayWxCount) * 100).toFixed(2) }} %</div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">单笔金额
                <el-tooltip class="item" effect="dark" content="今日业绩 / 今日签单数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{((item.todayPrice / item.todayCount)).toFixed(2) }} </div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">周转换率
                <el-tooltip class="item" effect="dark" content="本周签单数量 / 本周添加微信数" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{((item.weekCount / item.weekWxCount) * 100).toFixed(2) }} %</div>
            </el-col>
            <el-col :span="5">
              <div class="text-center text-gray">月转换率
                <el-tooltip class="item" effect="dark" content="本月签单数量 / 本月添加微信数" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{((item.monthCount / item.monthWxCount) * 100).toFixed(2) }} %</div>
            </el-col>
            <el-col :span="4">
              <div class="text-center text-gray">退费率
                <el-tooltip class="item" effect="dark" content="退费数量 / 签单数量" placement="top">
                  <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t ">{{((item.totalRefundCount / item.totalCount)  * 100).toFixed(2) }} %</div>
            </el-col>
          </el-row>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "index",
  data(){
    return {
      type:{
        1:'新签',
        2:'退费',
        3:'转介绍',
        4:'扩科目',
        5:'续费',
        6:'百度',
        7:'头条',
      },
      statistics: [],
      statisticsLoading: false,
      allMonthCount: 0,
      allMonthPrice: 0,
      allMonthRefundCount: 0,
      allMonthRefundPrice: 0,
      allMonthWxCount: 0,
      allTodayCount: 0,
      allTodayPrice: 0,
      allTodayWxCount: 0,
      allTotalCount: 0,
      allTotalPrice: 0,
      allTotalRefundCount: 0,
      allTotalRefundPrice: 0,
      allTotalWxCount: 0,
      allWeekCount: 0,
      allWeekPrice: 0,
      allWeekWxCount: 0,
      wxCountData: [],
      wxCountDataLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      date: [new Date(), new Date()],
    }
  },
  methods:{
    ...mapActions('sales',['manageStatistics','getWxCountDetail','getStatisticsDetail']),
    search(){
      this.allMonthCount = 0
      this.allMonthPrice = 0
      this.allMonthRefundCount = 0
      this.allMonthRefundPrice = 0
      this.allMonthWxCount = 0
      this.allTodayCount = 0
      this.allTodayPrice = 0
      this.allTodayWxCount= 0
      this.allTotalCount = 0
      this.allTotalPrice = 0
      this.allTotalRefundCount = 0
      this.allTotalRefundPrice = 0
      this.allTotalWxCount = 0
      this.allWeekCount = 0
      this.allWeekPrice = 0
      this.allWeekWxCount = 0
      this.getManageStatistics()
    },
    wxCountDetail(type,id){
      this.wxCountDataLoading = true
      let data = [];
      let all = id === 0 ? true : ''
      this.getWxCountDetail({type:type,userId:id,'allUser':all,startDate:this.date[0],endDate:this.date[1]}).then(res => {
        for (var item in res.data){
          let count = 0
          res.data[item].forEach(i => {
            count += i.count
          })
          data.push({
            name:item,
            count:count
          })
        }
        this.wxCountData = data
      }).finally(()=>{
        this.wxCountDataLoading = false
      })
    },
    statisticsDetail(type,id){
      this.wxCountDataLoading = true
      this.getStatisticsDetail({dateType:type,userId:id,startDate:this.date[0],endDate:this.date[1]}).then(res => {
        this.wxCountData = res.data
      }).finally(()=>{
        this.wxCountDataLoading = false
      })
    },
    async  getManageStatistics(){
      this.statisticsLoading = true
      let res = await this.manageStatistics({startDate:this.date[0],endDate:this.date[1]})
      this.statistics = res.data
      console.log(res)
      res.data.forEach(item => {
        this.allMonthCount += item.monthCount
        this.allMonthPrice += item.monthPrice
        this.allMonthRefundCount += item.monthRefundCount
        this.allMonthRefundPrice += item.monthRefundPrice
        this.allMonthWxCount += item.monthWxCount
        this.allTodayCount += item.todayCount
        this.allTodayPrice += item.todayPrice
        this.allTodayWxCount+= item.todayWxCount
        this.allTotalCount += item.totalCount
        this.allTotalPrice += item.totalPrice
        this.allTotalRefundCount += item.totalRefundCount
        this.allTotalRefundPrice += item.totalRefundPrice
        this.allTotalWxCount += item.totalWxCount
        this.allWeekCount += item.weekCount
        this.allWeekPrice += item.weekPrice
        this.allWeekWxCount += item.weekWxCount
      })
      this.statisticsLoading = false
    }
  },
  mounted() {
    this.getManageStatistics()
  }
}
</script>

<style>

</style>